
import { getUserCompanies } from "@/helpers/companies";
import {
  quoteMapActions,
  quoteMapState,
  quoteMapMutations
} from "@/store/modules/quote";
import Vue from "vue";
import { get } from "lodash";
import DisplayData from "@/components/DataView/DisplayData.vue";
import DisplayLoopingData from "@/components/DataView/DisplayLoopingData.vue";
import {
  dwellingTypeOptions,
  getDwellingLimitedDedOptions,
  propertyUsageOptions,
  structureTypeOptions
} from "@/forms/shared/quote.dwelling_fire";
import {
  tenantDwellingOptions,
  tenantEntranceOptions,
  tenantLimitDed3Options,
  tenantStructureTypeOptions
} from "@/forms/shared/quote.tenant";
import {
  getHomeOwnerLimitedDedOptions,
  numberOfNonWeatherClaimsOptions,
  paymentPlanOptions
} from "@/forms/shared/quote.home_owner";
import { getPHPAPIFormattedDate } from "@/helpers/dateProcessor";
import TerritoryDataViewer from "@/views/shared/quotes/Components/TerritoryViewer.vue";
import {
  roofStructureOptions,
  yearBuiltOptions,
  yesNoOptions,
  occupiedDailyOptions,
  accreditedBuilderName,
  hailResistantRoof,
  liabilityLimit,
  medLiability,
  occupancyOptions,
  billingPlanOptions,
  allRoofMaterialOptions,
  oldCompanies
} from "@/helpers/selectOptions";
import { numberOfStoriesOptions } from "@/forms/shared/quote.shared_underwriter_questions";
import { formatCurrencyCompactLong } from "@/plugins/filters";
import { downloadQuoteDocument } from "@/helpers/downloadLossForm";
import { object } from "dot-object";
import * as types from "@/store/mutation-types";
import { getAddressStringified } from "../../../helpers/addressFormatter/getAddressStringified";
import { authMapGetters } from "@/store/modules/auth";
import { capitalizeFirstLetter } from "../../../helpers/generalHelpers";
import { displayXML } from "@/helpers/displayXML";
interface IFilteredQuestion {
  preamble: string;
  key: string;
}
interface IData {
  didInit: boolean;
  error: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  expanded: boolean;
  rate: any;
  rating: any;
  questions: any;
  showChangeRequestStatus: boolean;
  selectedStatus: string;
  quoteAttachmentData?: any;
}
export default Vue.extend({
  name: "ViewSingleQuote",
  components: {
    DisplayData,
    TerritoryDataViewer,
    // AddressViewer,
    DisplayLoopingData,
    HOViewer: () =>
      import(
        "@/views/shared/quotes/Components/RatingDetailViewers/HOViewer.vue"
      ),
    TenantCondoViewer: () =>
      import(
        "@/views/shared/quotes/Components/RatingDetailViewers/TenantCondoViewer.vue"
      ),
    DwellingFireViewer: () =>
      import(
        "@/views/shared/quotes/Components/RatingDetailViewers/DwellingFireViewer.vue"
      ),
    LossReportViewer: () =>
      import("@/views/shared/quotes/Components/LossReportViewer.vue"),
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    ChangeRequestStatus: () =>
      import("@/components/ChangeRequests/ChangeRequestStatus.vue"),
    DisplayAttachment: () =>
      import("@/components/DataView/DisplayAttachment.vue")
  },
  props: {
    quoteId: {
      type: String,
      required: true
    },
    isLegacy: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      didInit: false,
      error: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The quote you are looking for cannot be found.",
      expanded: true,
      rate: {},
      rating: {},
      questions: {},
      showChangeRequestStatus: false,
      selectedStatus: "",
      quoteAttachmentData: []
    };
  },
  mounted() {
    this.initializePageData();
  },
  methods: {
    ...quoteMapActions(["getQuote", "updateQuote", "getSendingOrReceivingXML"]),
    ...quoteMapMutations({
      editField: types.SET_EDIT_FIELD
    }),
    restructAttachmentData(attachment: any) {
      const oldAttachments = [...this.quoteAttachmentData];
      const newAttachments = oldAttachments.filter(
        (data: any) => attachment._id !== data._id
      );
      newAttachments.push(attachment);
      this.quoteAttachmentData = this.sortAttachment(newAttachments);
    },
    sortAttachment(attachmentsData: any) {
      return attachmentsData.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    async initializePageData(): Promise<void> {
      try {
        await this.getQuote(this.quoteId);
        this.quoteAttachmentData = this.sortAttachment([
          ...this.editing.attachmentsData,
          ...this.editing.attachmentDataToRestore
        ]);
        this.selectedStatus = this.editing.status || "";
        if (!this.editing.isLegacy) {
          this.rating = this.editing.ratings.find(
            (rate: any) => rate._id === this.editing.ratingSelected
          );
          this.rate = get(this.rating, "rate");
          this.questions = this.getUnderWriterQuestions();
        }
        this.showChangeRequestStatus =
          !this.editing.isLegacy && this.userRole === "admin";
        this.didInit = true;

        let query: { appliesTo: string; companyNumber?: number } = {
          appliesTo: this.editing.policyType
        };
        if (this.editing.companyNumbers) {
          query["companyNumber"] = this.editing.companyNumbers;
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
        if (error?.status && error?.status === 403) {
          this.$router.push({
            path: "/not-authorized"
          });
        } else {
          this.resourceNotFound = true;
          this.error = error.message;
        }
      }
    },
    getPaymentPlan(paymentCode: string): string {
      let options = paymentPlanOptions.find(
        option => option.value === paymentCode
      );
      return options?.label || "";
    },
    getBillingPlan(billingCode: string): string {
      let options = billingPlanOptions.find(
        option => option.value === billingCode
      );
      return options?.label || "";
    },
    getPropertyUsage(propertyUsage: string): string {
      let options = propertyUsageOptions.find(
        option => option.value === propertyUsage
      );
      return options?.label || "";
    },
    getDwellingType(dwellingType: string, policyType: string): string {
      let options;
      if (policyType === "T") {
        options = tenantDwellingOptions.find(
          option => option.value === dwellingType
        );
      } else {
        options = dwellingTypeOptions.find(
          option => option.value === dwellingType
        );
      }
      return options?.label || "";
    },
    getStructureType(structureType: string, policyType: string): string {
      let options;
      if (policyType === "T") {
        options = tenantStructureTypeOptions.find(
          option => option.value === structureType
        );
      } else {
        options = structureTypeOptions.find(
          option => option.value === structureType
        );
      }
      return options?.label || "";
    },
    getOccupancy(occupancy: string): string {
      let options = occupancyOptions.find(option => option.value === occupancy);
      return options?.label || "";
    },
    getSelectCompanies() {
      let companies = this.userCompanies.filter((company: any) => {
        return get(this.editing, "companyNumbers", []).includes(company.value);
      });
      return companies;
    },
    getLimitDed1(limitDed1: string, policyType: string): string {
      const companies = this.getSelectCompanies();
      let options;
      let homeOwnerLimitDed1Options = getHomeOwnerLimitedDedOptions(companies);
      let dwellingFireLimitDed1Options = getDwellingLimitedDedOptions(
        companies
      );
      if (policyType === "H") {
        options = homeOwnerLimitDed1Options.find(
          (option: any) => option.value === limitDed1
        );
      } else if (policyType === "D") {
        options = dwellingFireLimitDed1Options.find(
          (option: any) => option.value === limitDed1
        );
      }
      return options?.label || "";
    },
    getLimitDed2(limitDed2: string, policyType: string): string {
      const companies = this.getSelectCompanies();
      let options;
      let homeOwnerLimitDed2Options = getHomeOwnerLimitedDedOptions(companies);
      if (policyType === "H") {
        options = homeOwnerLimitDed2Options.find(
          (option: any) => option.value === limitDed2
        );
      }
      return options?.label || "";
    },
    getLimitDed3(limitDed3: string, policyType: string): string {
      let options;
      if (policyType === "T") {
        options = tenantLimitDed3Options.find(
          option => option.value === limitDed3
        );
      }
      return options?.label || "";
    },
    getLimitLiability(limitLiability: string, policyType: string): string {
      let options;
      if (policyType === "T" || policyType === "H") {
        options = liabilityLimit.find(
          option => option.value === limitLiability
        );
      }
      return options?.label || "";
    },
    getLimitMed(limitMed: string, policyType: string): string {
      let options;
      if (policyType === "H" || policyType === "T") {
        options = medLiability.find(option => option.value === limitMed);
      }
      return options?.label || "";
    },
    getEntrance(entrance: string): string {
      let options = tenantEntranceOptions.find(
        option => option.value === entrance
      );
      return options?.label || "";
    },
    getRoofMaterial(roofMaterial: string): string {
      let options = allRoofMaterialOptions.find(
        option => option.value === roofMaterial
      );
      return options?.label || "";
    },
    getYear(year: number): string {
      let options = yearBuiltOptions.find(
        (option: any) => option.value === year
      );
      return options?.label || "";
    },
    getYesOrNo(value: string): string {
      let options = yesNoOptions.find(
        option =>
          get(option, "value", "").toLowerCase() === value?.toLowerCase()
      );
      return options?.label || "";
    },
    getNumberOfNonWeatherClaims(numberOfNonWeatherClaims: number): string {
      let options = numberOfNonWeatherClaimsOptions.find(
        option => option.value === numberOfNonWeatherClaims
      );
      return options?.label || "";
    },
    getNumberOfStories(numberOfStories: string): string {
      let options = numberOfStoriesOptions.find(
        option => option.value == numberOfStories
      );
      return options?.label || "";
    },
    getRoofStructure(roofStructure: string): string {
      let options = roofStructureOptions.find(
        option => option.value === roofStructure
      );
      return options?.label || "";
    },
    getAccreditedBulderName(accreditedBulder: string): string {
      let options = accreditedBuilderName.find(
        option => option.value === accreditedBulder
      );
      return options?.label || "";
    },
    getOccupiedDaily(occupiedDaily: string): string {
      let vacantOptions;
      const showVacantOption =
        this.editing &&
        (this.editing.companyNumbers.every((num: number) =>
          [18, 23, 24, 26, 28].includes(num)
        ) ||
          this.editing.policyType === "D")
          ? true
          : false;
      vacantOptions = showVacantOption ? occupiedDailyOptions : yesNoOptions;

      let options = vacantOptions.find(
        option => option.value === occupiedDaily
      );
      return options?.label || "";
    },
    getHailResistantRoof(hailResistantRoofName: string): string {
      let options = hailResistantRoof.find(
        option => option.value === hailResistantRoofName
      );
      return options?.label || "";
    },
    getColonialHailResistantRoof(hailResistantRoofName: string): string {
      let colonialHailResistantRoof = hailResistantRoof.slice(1, 5);
      let options = colonialHailResistantRoof.find(
        option => option.value === hailResistantRoofName
      );
      return options?.label || "";
    },
    getCompany(data: any): string | undefined {
      const companyNumber = this.editing.isLegacy
        ? get(data, "companyNumbers[0]", "")
        : get(data, "ratingSelectedData.companyNumber", "");

      let companyName = "";
      if (
        this.userCompanies instanceof Array &&
        this.userCompanies.length > 0
      ) {
        let options = this.userCompanies.find(
          option => option.value === companyNumber
        );
        companyName = options?.label || "";
      }
      const oldCompanyNumbers = oldCompanies.map(company => company.value);
      if (
        this.editing.isLegacy &&
        oldCompanyNumbers.includes(companyNumber.toString())
      ) {
        const company = oldCompanies.find(
          option => option.value == companyNumber
        );
        if (company) {
          companyName = company.label;
        }
      }
      return companyName;
    },
    formatCurrency(value: any): string {
      if ((value && !isNaN(value)) || value === 0) {
        return formatCurrencyCompactLong(value);
      } else {
        return "N/A";
      }
    },
    getUnderWriterQuestions(): IFilteredQuestion[] {
      const underwritingQuestionsFromDB = get(
        this.editing,
        "underwritingQuestions",
        []
      );
      const formattedQuestionsFromDB = underwritingQuestionsFromDB.map(
        (singleQuestion: any) => {
          return {
            preamble: singleQuestion.question,
            key: singleQuestion.key
          };
        }
      );

      return formattedQuestionsFromDB.filter((question: IFilteredQuestion) => {
        const responseKey = get(
          this.editing,
          `underwritingResponses.${question.key}`
        );
        return responseKey !== "";
      });
    },
    getAnswer(questionKey: string): string {
      let options = get(
        this.editing,
        `underwritingResponses.${questionKey}`,
        {}
      );
      return options?.answer;
    },
    formatDate(key: string): string {
      let processedDate = get(this.editing, key, "") || "";
      return processedDate
        ? getPHPAPIFormattedDate(new Date(processedDate))
        : "";
    },
    async printQuote() {
      try {
        await downloadQuoteDocument({
          quoteIds: [this.editing._id],
          rateId: this.editing.ratingSelected
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(error.message);
      }
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "print":
          this.printQuote();
          break;
        case "VS":
          this.sendingXMLHandler(event);
          break;
      }
    },
    async sendingXMLHandler(responseType: "VS" | "VR"): Promise<void> {
      try {
        const response = await this.getSendingOrReceivingXML({
          rateId: this.editing.ratingSelected,
          responseType,
          isBinding: true
        });
        const { rawXML } = response.data;
        displayXML(rawXML);
      } catch (error) {
        this.error = (error as Error).message || "";
        this.$bugSnagClient.notify(error);
      }
    },
    getProperty(data: object, property: keyof typeof object): string {
      return data ? data[property] : "";
    },
    async changeStatus(action: string): Promise<void> {
      try {
        this.selectedStatus = action;
        const message = `Are you sure you want to change this quote to ${action}?`;
        await this.$confirm(`${message}. Continue?`, "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          closeOnClickModal: false,
          showClose: false,
          cancelButtonClass:
            "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
        });
        this.editField({
          key: "status",
          value: action
        });
        await this.updateQuote({
          id: this.$route.params.quoteId,
          update: { status: this.editing.status }
        });
        this.$appNotifySuccess("Status Changed");
      } catch (e) {
        this.selectedStatus = this.editing.status || "";
        if (e && e.message !== "cancel") {
          this.$bugSnagClient.notify(e);
        }
      }
    },
    checkSeaTerr(companyNumber: number): boolean {
      let companyTerritories = this.editing.territoryData.territoryInfo
        .companyTerritories;
      companyTerritories.find((terr: any) => {
        return terr.companyNumber === companyNumber && terr.seaTerr;
      });
      return false;
    },
    getAddressStringified(address: any): string {
      return getAddressStringified(address);
    },
    checkIfDiscountHasValue(): boolean {
      return (
        this.editing?.discount?.accreditedBuilder ||
        this.editing?.discount?.monitoredAlarmAtlas ||
        this.editing?.discount?.companionAutoAtlas ||
        this.editing?.discount?.lossFreeExperienceCredit ||
        !!this.editing?.discount?.hailResistantRoofAtlas ||
        !!this.editing?.discount?.yearInstalledAtlas ||
        this.editing?.discount?.newHomeCredit ||
        !!this.editing?.discount?.yearOfRoof ||
        !!this.editing?.discount?.hailResistantRoof ||
        !!this.editing?.discount?.ageOfHome ||
        this.editing?.discount?.companionAuto ||
        this.editing?.discount?.hvacAge ||
        !!this.editing?.discount?.hvacInstallationYear ||
        this.editing?.discount?.monitoredAlarmSW ||
        this.editing?.discount?.ageOfInsuredCheck ||
        !!this.editing?.discount?.ageOfInsuredSW
      );
    },
    capitalizeFirstLetter(word: string): string {
      return capitalizeFirstLetter(word);
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    ...authMapGetters(["getCurrentUser"]),
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: { title: string; command: string }[];
    } | null {
      const options = [];
      if (this.$isCurrentUserAdmin) {
        options.push({
          title: "View Sending XML",
          command: "VS"
        });
      }
      return {
        text: "Actions",
        key: "actions",
        subItems: options
      };
    },
    userCompanies(): any[] {
      let companies = getUserCompanies(
        "quote",
        get(this.editing, "companyValidations.allowedCompanies")
      );

      return companies;
    },
    getApplicantName(): string {
      const firstName = get(
        this.editing.quoteApplication.applicant,
        "firstName",
        ""
      );

      const middleName = get(
        this.editing.quoteApplication.applicant,
        "middleName",
        ""
      );

      const lastName = get(
        this.editing.quoteApplication.applicant,
        "lastName",
        ""
      );

      return `${firstName} ${middleName} ${lastName}`;
    },
    userRole(): string {
      return get(this.getCurrentUser, "role", "");
    },
    getData(): any {
      let results = {};
      results = {
        sections: [
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Effective Date",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.effectiveDate,
                value:
                  !!this.editing && !!this.editing.effectiveDate
                    ? getPHPAPIFormattedDate(
                        new Date(get(this.editing, "effectiveDate"))
                      )
                    : ""
              },
              {
                informationColumnSize: "2",
                key: "Payment Plan",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.paymentPlan,
                value: this.getPaymentPlan(get(this.editing, "paymentPlan"))
              },
              {
                informationColumnSize: "2",
                key: "Payment Plan",
                showColumn: get(this.editing, "paymentPlan") === "1",
                isArray: false,
                value: this.getBillingPlan(get(this.editing, "billing"))
              }
            ]
          },
          {
            sectionTitle: "Property Usage & Construction",
            showSection: this.editing && this.editing.policyType !== "T",
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Property Usage",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.useAge,
                value: this.getPropertyUsage(get(this.editing, "useAge"))
              },
              {
                informationColumnSize: "2",
                key: "Dwelling Type",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.dwellType,
                value: this.getDwellingType(
                  get(this.editing, "dwellType"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Building Structure Type",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.structureType,
                value: this.getStructureType(
                  get(this.editing, "structureType"),
                  get(this.editing, "policyType")
                )
              }
            ]
          },
          {
            sectionTitle: "Property Usage & Construction",
            showSection: this.editing && this.editing.policyType === "T",
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Dwelling Type",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.dwellType,
                value: this.getDwellingType(
                  get(this.editing, "dwellType"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Building Structure Type",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.structureType,
                value: this.getStructureType(
                  get(this.editing, "structureType"),
                  get(this.editing, "policyType")
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: this.editing && this.editing.policyType === "H",
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Occupancy",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.occupancy,
                value: this.getOccupancy(get(this.editing, "occupancy"))
              }
            ]
          },
          {
            sectionTitle: "Limits and Deductible Modifiers",
            description:
              "* Ded 1 Wind/Hurr/Hail * Ded 2 All Other Perils – “Not all Deductible limits apply to all companies.”",
            showSection: this.editing && this.editing.policyType === "H",
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Ded 1",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitDed1,
                value: this.getLimitDed1(
                  get(this.editing, "limitDed1"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Liability Limit",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitLiab,
                value: this.getLimitLiability(
                  get(this.editing, "limitLiab"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Ded 2",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitDed2,
                value: this.getLimitDed2(
                  get(this.editing, "limitDed2"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Med Limit",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitMed,
                value: this.getLimitMed(
                  get(this.editing, "limitMed"),
                  get(this.editing, "policyType")
                )
              }
            ]
          },
          {
            sectionTitle: "Limits and Deductible Modifiers",
            showSection: this.editing && this.editing.policyType === "T",
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Ded 3",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitDed3,
                value: this.getLimitDed3(
                  get(this.editing, "limitDed3"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Liability Limit",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitLiab,
                value: this.getLimitLiability(
                  get(this.editing, "limitLiab"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Entrance",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.entrance,
                value: this.getEntrance(get(this.editing, "entrance"))
              },
              {
                informationColumnSize: "2",
                key: "Med Limit",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitMed,
                value: this.getLimitMed(
                  get(this.editing, "limitMed"),
                  get(this.editing, "policyType")
                )
              }
            ]
          },
          {
            sectionTitle: "Limits and Deductible Modifiers",
            description:
              "* Ded 1 Wind/Hurr/Hail * Ded 2 All Other Perils – “Not all Deductible limits apply to all companies.”",
            showSection: this.editing && this.editing.policyType === "D",
            showDivider: true,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: "2",
                key: "EC Ded",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.limitDed1,
                value: this.getLimitDed1(
                  get(this.editing, "limitDed1"),
                  get(this.editing, "policyType")
                )
              },
              {
                informationColumnSize: "2",
                key: "Roof Material",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.roofMaterial,
                value: this.getRoofMaterial(get(this.editing, "roofMaterial"))
              }
            ]
          },
          {
            sectionTitle: "Dwelling Information",
            showSection:
              this.editing &&
              (this.editing.policyType === "D" ||
                this.editing.policyType === "H"),
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Year Built",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.yearBuild,
                value: this.getYear(get(this.editing, "yearBuild"))
              },
              {
                informationColumnSize: "2",
                key: "Roof Year",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.yearOfRoof,
                value: this.getYear(get(this.editing, "yearOfRoof"))
              },
              {
                informationColumnSize: "2",
                key: "Square Feet",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.squareFeet,
                value: get(this.editing, "squareFeet")
              }
            ]
          },
          {
            sectionTitle: "Dwelling Information",
            showSection: this.editing && this.editing.policyType === "T",
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Year Built",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.yearBuild,
                value: this.getYear(get(this.editing, "yearBuild"))
              },
              {
                informationColumnSize: "2",
                key: "Roof Year",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.yearOfRoof,
                value: this.getYear(get(this.editing, "yearOfRoof"))
              },
              {
                informationColumnSize: "2",
                key: "Contents Amount",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.amountUPP,
                value: get(this.editing, "amountUPP")
              },
              {
                informationColumnSize: "2",
                key: "Occupancy",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.occupancy,
                value: this.getOccupancy(get(this.editing, "occupancy"))
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              this.editing &&
              (this.editing.policyType === "D" ||
                this.editing.policyType === "H"),
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Building Amount",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.amountDWP,
                value: get(this.editing, "amountDWP")
              },
              {
                informationColumnSize: "2",
                key: "Contents Amount",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.amountUPP,
                value: get(this.editing, "amountUPP")
              },
              {
                informationColumnSize: "2",
                key: "Other Structures",
                showColumn:
                  !!this.editing &&
                  this.editing.policyType === "H" &&
                  !!this.editing.amountOTS,
                isArray: false,
                value: get(this.editing, "amountOTS")
              },
              {
                informationColumnSize: "2",
                key: "Occupancy",
                showColumn:
                  !!this.editing &&
                  this.editing.policyType === "D" &&
                  !!this.editing.occupancy,
                isArray: false,
                value: this.getOccupancy(get(this.editing, "occupancy"))
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: "2",
                key: "Trampoline on the premises?",
                isArray: false,
                showColumn:
                  !!this.editing && !!this.editing.trampolineOnPremises,
                value: this.getYesOrNo(
                  get(this.editing, "trampolineOnPremises")
                )
              },
              {
                informationColumnSize: "2",
                key: "Number of Non-Weather or Non-Appliance Claims",
                isArray: false,
                showColumn:
                  !!this.editing && !!this.editing.numberOfNonWeatherClaims,
                value: this.getNumberOfNonWeatherClaims(
                  get(this.editing, "numberOfNonWeatherClaims")
                )
              }
            ]
          },
          {
            sectionTitle: "Underwriting",
            showSection: true,
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Replacement Cost",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.replacementCost,
                value: get(this.editing, "replacementCost")
              },
              {
                informationColumnSize: "2",
                key: "Market Value",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.marketValue,
                value: get(this.editing, "marketValue")
              },
              {
                informationColumnSize: "2",
                key: "Number Of Stories",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.numberOfStories,
                value: this.getNumberOfStories(
                  get(this.editing, "numberOfStories")
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Roof Structure",
                showColumn: !!this.editing && !!this.editing.roofStructure,
                isArray: false,
                value: this.getRoofStructure(get(this.editing, "roofStructure"))
              },
              {
                informationColumnSize: "2",
                key: "Occupied Daily",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.occupiedDaily,
                value: this.getOccupiedDaily(get(this.editing, "occupiedDaily"))
              },
              {
                informationColumnSize: "2",
                key: "Purchase Date",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.purchaseDate,
                value: this.formatDate("purchaseDate")
              }
            ]
          }
        ]
      };
      return results;
    },
    getDiscountData(): any {
      let results = {};
      results = {
        sections: [
          {
            sectionTitle: "Atlas Credits",
            showSection:
              get(this.editing, "discounts.accreditedBuilder") ||
              get(this.editing, "discounts.accreditedBuilderName") ||
              get(this.editing, "discounts.monitoredAlarmAtlas") ||
              get(this.editing, "discounts.companionAutoAtlas"),
            showDivider: false,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Accredited Builder - (10% - 15%)",
                showColumn: get(this.editing, "discounts.accreditedBuilder"),
                isArray: false,
                value: get(this.editing, "discounts.accreditedBuilder", "")
              },
              {
                informationColumnSize: "2",
                key: "Accredited Builder Name",
                showColumn: get(
                  this.editing,
                  "discounts.accreditedBuilderName"
                ),
                isArray: false,
                value: this.getAccreditedBulderName(
                  get(this.editing, "discounts.accreditedBuilderName", "")
                )
              },
              {
                informationColumnSize: "2",
                key: "Monitored Alarm - (5%)",
                showColumn: get(this.editing, "discounts.monitoredAlarmAtlas"),
                isArray: false,
                value: get(this.editing, "discounts.monitoredAlarmAtlas", "")
              },
              {
                informationColumnSize: "2",
                key: "Companion Auto (5%)",
                showColumn: get(this.editing, "discounts.companionAutoAtlas"),
                isArray: false,
                value: get(this.editing, "discounts.companionAutoAtlas", "")
              },
              {
                informationColumnSize: "2",
                key: "Fortified Roof",
                showColumn: get(this.editing, "discounts.fortifiedRoof"),
                isArray: false,
                value: get(this.editing, "discounts.fortifiedRoof", "")
              }
            ]
          },
          {
            sectionTitle: "Atlas TDI Credits",
            showSection:
              get(this.editing, "discounts.hailResistantRoofAtlas") ||
              get(this.editing, "discounts.yearInstalledAtlas"),
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Hail Resistant Roof",
                showColumn: get(
                  this.editing,
                  "discounts.hailResistantRoofAtlas"
                ),
                isArray: false,
                value: this.getHailResistantRoof(
                  get(this.editing, "discounts.hailResistantRoofAtlas")
                )
              },
              {
                informationColumnSize: "2",
                key: "Year Installed",
                showColumn: get(this.editing, "discounts.yearInstalledAtlas"),
                isArray: false,
                value: get(this.editing, "discounts.yearInstalledAtlas", "")
              }
            ]
          },
          {
            sectionTitle: "Roadrunner Credits",
            showSection:
              get(this.editing, "discounts.newHomeCredit") ||
              get(this.editing, "discounts.lossFreeExperienceCredit") ||
              get(this.editing, "discounts.companionAuto") ||
              get(this.editing, "discounts.hvacAge") ||
              get(this.editing, "discounts.monitoredAlarmSW") ||
              get(this.editing, "discounts.ageOfInsuredCheck") ||
              get(this.editing, "discounts.ageOfInsuredSW") ||
              get(this.editing, "discounts.ageOfHome") ||
              get(this.editing, "discounts.hvacInstallationYear"),
            showDivider: true,
            sectionColumnSize: "8",
            information: []
          },
          {
            sectionTitle: "",
            showSection: get(this.editing, "discounts.newHomeCredit"),
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "New Home Credit",
                showColumn: get(this.editing, "discounts.newHomeCredit"),
                isArray: false,
                value: get(this.editing, "discounts.newHomeCredit")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              get(this.editing, "discounts.lossFreeExperienceCredit") ||
              get(this.editing, "discounts.companionAuto") ||
              get(this.editing, "discounts.hvacAge"),
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key:
                  "Loss Free Experience Credit (5%) – Must be loss free with Atlas for two years",
                showColumn: get(
                  this.editing,
                  "discounts.lossFreeExperienceCredit"
                ),
                isArray: false,
                value: get(
                  this.editing,
                  "discounts.lossFreeExperienceCredit",
                  ""
                )
              },
              {
                informationColumnSize: "2",
                key: "Companion Auto (5%)",
                showColumn: get(this.editing, "discounts.companionAuto"),
                isArray: false,
                value: get(this.editing, "discounts.companionAuto")
              },
              {
                informationColumnSize: "2",
                key: "HVAC Age - (5 years or newer)",
                showColumn: get(this.editing, "discounts.hvacAge"),
                isArray: false,
                value: get(this.editing, "discounts.hvacAge")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              get(this.editing, "discounts.hvacInstallationYear") ||
              get(this.editing, "discounts.monitoredAlarmSW") ||
              get(this.editing, "discounts.ageOfInsuredCheck"),
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "HVAC Installation Year",
                showColumn: get(this.editing, "discounts.hvacInstallationYear"),
                isArray: false,
                value: get(this.editing, "discounts.hvacInstallationYear")
              },
              {
                informationColumnSize: "2",
                key: "Monitored Alarm - (5%) - Statewide",
                showColumn: get(this.editing, "discounts.monitoredAlarmSW"),
                isArray: false,
                value: get(this.editing, "discounts.monitoredAlarmSW")
              },
              {
                informationColumnSize: "2",
                key: "Mature Citizen- (55 or older)",
                showColumn: get(this.editing, "discounts.ageOfInsuredCheck"),
                isArray: false,
                value: get(this.editing, "discounts.ageOfInsuredCheck")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              get(this.editing, "discounts.SW") ||
              get(this.editing, "discounts.ageOfHome"),
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Age of Home",
                showColumn: get(this.editing, "discounts.ageOfHome"),
                isArray: false,
                value: get(this.editing, "discounts.ageOfHome", "")
              },
              {
                informationColumnSize: "2",
                key: "Age of Insured - Statewide",
                showColumn: get(this.editing, "discounts.ageOfInsuredSW"),
                isArray: false,
                value: get(this.editing, "discounts.ageOfInsuredSW")
              }
            ]
          },
          {
            sectionTitle: "Roadrunner TDI Credits",
            showSection:
              get(this.editing, "discounts.yearOfRoof") ||
              get(this.editing, "discounts.hailResistantRoof"),
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Year of Roof",
                showColumn: get(this.editing, "discounts.yearOfRoof"),
                isArray: false,
                value: get(this.editing, "discounts.yearOfRoof")
              },
              {
                informationColumnSize: "2",
                key: "Hail Resistant Roof",
                showColumn: get(this.editing, "discounts.hailResistantRoof"),
                isArray: false,
                value: this.getColonialHailResistantRoof(
                  get(this.editing, "discounts.hailResistantRoof")
                )
              }
            ]
          }
        ]
      };
      return results;
    },
    getAddressData(): any {
      let results = {};
      results = {
        sections: [
          {
            sectionTitle: "Applicant Information",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "First Name",
                isArray: false,
                showColumn: !!this.editing.quoteApplication.applicant.firstName,
                value: capitalizeFirstLetter(
                  get(this.editing, "quoteApplication.applicant.firstName", "")
                )
              },
              {
                informationColumnSize: "2",
                key: "Last Name",
                isArray: false,
                showColumn: !!this.editing.quoteApplication.applicant.lastName,
                value: capitalizeFirstLetter(
                  get(this.editing, "quoteApplication.applicant.lastName", "")
                )
              },
              {
                informationColumnSize: "2",
                key: "Email",
                isArray: false,
                showColumn: !!this.editing.quoteApplication.applicant.email,
                value: get(this.editing, "quoteApplication.applicant.email", "")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Date of Birth",
                isArray: false,
                showColumn: !!this.editing.quoteApplication.applicant
                  .dateOfBirth,
                value:
                  this.editing &&
                  this.editing.quoteApplication &&
                  this.editing.quoteApplication.applicant &&
                  this.editing.quoteApplication.applicant.dateOfBirth
                    ? getPHPAPIFormattedDate(
                        new Date(
                          get(
                            this.editing,
                            "quoteApplication.applicant.dateOfBirth"
                          )
                        )
                      )
                    : ""
              },
              {
                informationColumnSize: "2",
                key: "Marital Status",
                isArray: false,
                showColumn: !!this.editing.quoteApplication.applicant
                  .maritalStatus,
                value: get(
                  this.editing,
                  "quoteApplication.applicant.maritalStatus",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "Risk Address",
            showSection: true,
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "8",
                key: "",
                isArray: false,
                showColumn: !!this.editing.riskAddress,
                value: `${getAddressStringified(
                  get(this.editing, "riskAddress", "")
                )}`
              }
            ]
          },
          {
            sectionTitle: "Previous Address",
            showSection:
              this.editing &&
              this.editing.quoteApplication &&
              this.editing.quoteApplication.previousAddress &&
              !!this.editing.quoteApplication.previousAddress.houseNumber &&
              !!this.editing.quoteApplication.previousAddress.streetName &&
              !!this.editing.quoteApplication.previousAddress.city &&
              !!this.editing.quoteApplication.previousAddress.state &&
              !!this.editing.quoteApplication.previousAddress.zipCode,
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "",
                isArray: false,
                value: `${getAddressStringified(
                  get(this.editing, "quoteApplication.previousAddress", "")
                )}`
              }
            ]
          }
        ]
      };
      return results;
    },
    getCoApplicantData(): any[] {
      let results = [];
      for (
        let i = 0;
        i < get(this.editing, "quoteApplication.coApplicants.length");
        i++
      ) {
        results.push({
          sections: [
            {
              sectionTitle: "",
              showSection: true,
              showDivider: false,
              sectionColumnSize: "8",
              information: [
                {
                  informationColumnSize: "2",
                  key: "First Name",
                  isArray: false,
                  value: get(
                    this.editing.quoteApplication.coApplicants[i],
                    "firstName"
                  )
                },
                {
                  informationColumnSize: "2",
                  key: "Last Name",
                  isArray: false,
                  value: get(
                    this.editing.quoteApplication.coApplicants[i],
                    "lastName"
                  )
                },
                {
                  informationColumnSize: "2",
                  key: "Email",
                  isArray: false,
                  value: get(
                    this.editing.quoteApplication.coApplicants[i],
                    "email"
                  )
                }
              ]
            },
            {
              sectionTitle: "",
              showSection: true,
              showDivider: false,
              sectionColumnSize: "8",
              information: [
                {
                  informationColumnSize: "2",
                  key: "Date of Birth",
                  isArray: false,
                  value: getPHPAPIFormattedDate(
                    new Date(
                      get(
                        this.editing.quoteApplication.coApplicants[i],
                        "dateOfBirth"
                      )
                    )
                  )
                },
                {
                  informationColumnSize: "2",
                  key: "Marital Status",
                  isArray: false,
                  value: get(
                    this.editing.quoteApplication.coApplicants[i],
                    "maritalStatus"
                  )
                }
              ]
            }
          ]
        });
      }
      return results;
    },
    getMortgagesData(): any[] {
      let results = [];
      for (let i = 0; i < get(this.editing, "mortgages").length; i++) {
        results.push({
          sections: [
            {
              sectionTitle: "Mortgage #" + (i + 1),
              showSection: true,
              showDivider: false,
              sectionColumnSize: "8",
              information: [
                {
                  informationColumnSize: "2",
                  key: "Mortgage Name",
                  isArray: false,
                  value: get(this.editing.mortgages[i], "mortgageName")
                },
                {
                  informationColumnSize: "2",
                  key: "Address",
                  isArray: false,
                  value: `${get(
                    this.editing.mortgages[i],
                    "address",
                    ""
                  )}, ${get(this.editing.mortgages[i], "city", "")}, ${get(
                    this.editing.mortgages[i],
                    "state",
                    ""
                  )}, ${get(this.editing.mortgages[i], "zipCode", "")}`
                }
              ]
            }
          ]
        });
      }
      return results;
    },
    companyNumber(): number | null {
      return this.editing.isLegacy
        ? get(this.editing, "companyNumbers[0]", null)
        : get(this.editing, "ratingSelectedData.companyNumber", null);
    },
    hasLossReport(): boolean {
      return (
        this.editing &&
        this.editing.lossReport &&
        this.editing.lossReport.lossInformation.length > 0
      );
    },
    lossInformation(): any[] {
      if (this.hasLossReport) {
        return this.editing.lossReport.lossInformation;
      }
      return [];
    },
    policyNumber(): string | undefined {
      if (this.editing && this.editing.bindingResponse) {
        return this.editing.bindingResponse.policyNumber;
      }
      return undefined;
    },
    endorsements(): any[] {
      return get(
        this.editing,
        "ratingSelectedData.rate.Quote.m_Endorsement",
        {}
      );
    },
    territoryDataViewerCompanies(): any[] {
      const companies = this.editing.companyTerritoryData;
      const selectedCompanies = this.editing.companyNumbers;
      return companies.filter((company: any) =>
        selectedCompanies.includes(company.companyNumber)
      );
    },
    capeDataMessage(): string {
      if (this.policyNumber) {
        return get(
          this.editing,
          "propertyAnalytics.capeDataEvaluationResponse.msg",
          ""
        );
      }
      return "";
    }
  }
});
